import { ChangeDetectionStrategy, Component, computed, inject, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { asRequired, controlValuesSignal, Duration, Instant, localDateTimeToInstant, PilotageState, PilotStationId, routeBoundControls, toLocalDateTime, ZoneId, ZoneParamNames, ZonePickerComponent } from "common";
import { SessionContext } from "../../session/session-context.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { TrafficLogPilotage } from "apina-frontend";
import { DateFieldComponent } from "../../forms/date-field/date-field.component";
import { PreloadedDataService } from "../../data/preloaded-data.service";

const PARAM_PILOTS = "pilots";
const PARAM_WAITING = "waiting";
const PARAM_PERIOD = "period";
const PARAM_START = "start";
const PARAM_PILOTAGE_PILOT_STATION = "pilotage.pilot-station";
const WAITING_TRUE_VALUE = "include";

export const PILOTAGE_ZONE_PARAM_NAMES: ZoneParamNames = {
    area: "pilotage.area",
    pilotageArea: "pilotage.pilotage-area",
    pilotStation: PARAM_PILOTAGE_PILOT_STATION,
};

const PILOT_ZONE_PARAM_NAMES: ZoneParamNames = {
    area: "pilot.area",
    pilotageArea: "pilot.pilotage-area",
    pilotStation: "pilot.pilot-station",
};

@Component({
    selector: 'app-scheduling-filters',
    templateUrl: 'scheduling-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        ZonePickerComponent,
        DateFieldComponent,
    ]
})
export class SchedulingFiltersComponent {

    readonly form = new FormGroup({
        includeWaitingPilotages: routeBoundControls.booleanWithCustomTrue(PARAM_WAITING, WAITING_TRUE_VALUE),
        includeAllPilots: routeBoundControls.booleanWithCustomTrue(PARAM_PILOTS, "all"),
        pilotageZone: routeBoundControls.zone(PILOTAGE_ZONE_PARAM_NAMES),
        pilotZone: routeBoundControls.zone(PILOT_ZONE_PARAM_NAMES),
        periodLength: routeBoundControls.int(PARAM_PERIOD, 1),
        startTime: routeBoundControls.optionalLocalDate(PARAM_START),
    });

    @Output() readonly queryParams: Observable<SchedulingQueryParams>;
    private readonly referenceData = inject(PreloadedDataService).referenceData;

    constructor(sessionContext: SessionContext) {
        sessionContext.bindZoneFromControl(this.form.controls.pilotageZone);

        const formSignal = controlValuesSignal(this.form);
        this.queryParams = toObservable(computed(() => {
            const form = asRequired(formSignal());

            return {
                pilotagePilotStationIds: this.pilotStationIdsForZone(form.pilotageZone),
                pilotPilotStationIds: this.pilotStationIdsForZone(form.pilotZone ?? form.pilotageZone),
                allPilots: form.includeAllPilots,
                waitingPilotages: form.includeWaitingPilotages,
                startTime: form.startTime == null ? null : localDateTimeToInstant(form.startTime.atStartOfDay()),
                duration: Duration.ofDays(form.periodLength)
            };
        }));
    }

    private pilotStationIdsForZone(zoneId: ZoneId | null): PilotStationId[] {
        if (zoneId == null) return [];

        return this.referenceData.pilotStationIdsFor(zoneId);
    }
}

export interface SchedulingQueryParams {
    pilotagePilotStationIds: PilotStationId[];
    pilotPilotStationIds: PilotStationId[];
    allPilots: boolean;
    waitingPilotages: boolean;
    startTime: Instant | null;
    duration: Duration;
}

export function createSchedulingFilterParams(pilotage: TrafficLogPilotage): Record<string, unknown> {
    const startTime = toLocalDateTime(pilotage.startTime);
    return {
        [PARAM_START]: startTime.toLocalDate().toString(),
        [PARAM_WAITING]: pilotage.state === PilotageState.ESTIMATE ? WAITING_TRUE_VALUE : undefined,
        [PARAM_PERIOD]: startTime.hour() >= 18 ? 2 : 1, // If the pilotage starts in the evening, show next day as well
        [PARAM_PILOTAGE_PILOT_STATION]: pilotage.routeStationId.toString()
    };
}
